<template>
  <v-main class="fill-height">
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
        >
          <v-card class="elevation-12">
            <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="login"
            >
              <v-toolbar
                color="black"
                dark
                flat
              >
                <v-toolbar-title>Вход</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  v-model="username"
                  :rules="[rules.required]"
                  :error-messages="fieldError.username"
                  label="Имя"
                  required
                  prepend-icon="mdi-account"
                />
                <v-text-field
                  v-model="password"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :error-messages="fieldError.password"
                  :type="show_password ? 'text' : 'password'"
                  label="Пароль"
                  required
                  prepend-icon="mdi-lock"
                  @click:append="show_password = !show_password"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn 
                  color="primary" 
                  :disabled="!valid" 
                  :loading="loading"
                  type="submit"
                >
                  Войти
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",

  data: () => ({
    show_password: false,
    valid: false,

    username: "",
    password: "",

    loading: false,
    fieldError: {},

    rules: {
      required: (v) => !!v || "Обязательное поле",
      minPass: (v) => v.length >= 8 || "Пароль должен быть не менее 8 символов",
    },
  }),
  methods: {
    ...mapActions("auth", ["AUTH_REQUEST"]),
    ...mapActions(["alertError", "alertSuccess"]),
    login: function () {
      const { username, password } = this;
      this.loading = true;
      this.AUTH_REQUEST({ username, password })
        .then(() => {
          this.alertSuccess("Авторизация прошла успешно!");
          this.$router.push("/");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 400) {
            this.fieldError = error.response.data;
          } else if (
            error.response.status === 500 &&
            error.response.data["code"] === "auth"
          ) {
            this.alertError(error.response.data["detail"]);
          }
        });
    },
  },
};
</script>
